import { useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { TextField, Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutlined';
import { translatePipe } from '../i18n';
import authService from '../api/auth_service';
import { requiredEmailField } from '../utils/validationField';
import HeaderLogo from '../components/HeaderLogo';
import '../App.scss';

const validationSchema = yup.object({
  email: requiredEmailField
});

function ResetPassword() {
  const [pendingResetPassword, setPendingResetPassword] =
    useState<boolean>(false);
  const [showRecaptcha, setShowRecaptcha] = useState<boolean>(false);
  const [successStatus, setSuccessStatus] = useState<boolean | null>(false);
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  useEffect(() => {
    authService.resetPasswordCreateOptions().then((resp) => {
      setShowRecaptcha(resp?.data.recaptcha.required);
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let recaptchaCode: any = '';
      if (showRecaptcha) {
        recaptchaCode = await recaptchaRef?.current?.executeAsync();
        recaptchaRef?.current?.reset();
      }
      setPendingResetPassword(true);
      if ((recaptchaCode && showRecaptcha) || !showRecaptcha) {
        const payload = recaptchaCode
          ? {
              email: values.email,
              recaptcha_code: recaptchaCode
            }
          : {
              email: values.email
            };
        authService
          .resetPasswordCreate(payload)
          .then(() => {
            setPendingResetPassword(false);
            setSuccessStatus(true);
          })
          .catch(() => {
            setPendingResetPassword(false);
          });
      }
    }
  });

  const renderSuccessStatus = useMemo(() => {
    if (successStatus) {
      return (
        <div className="flex a-i-c success-message">
          <CheckCircleOutlineIcon />
          <div>{translatePipe('check_email_for_recovery_link')}</div>
        </div>
      );
    }
    return null;
  }, [successStatus]);

  return (
    <div className="page-wrapper flex f-d-col a-i-c">
      <HeaderLogo />
      <div className="body-wrapper flex a-i-c">
        <div className="form-wrapper flex f-d-col j-c a-i-c">
          <div className="title-form">
            {translatePipe('password_reset_page_header')}
          </div>
          {renderSuccessStatus}
          <form
            className="flex f-d-col w-100"
            autoComplete="off"
            onSubmit={formik.handleSubmit}
          >
            {!successStatus && (
              <TextField
                size="small"
                name="email"
                id="outlined-basic"
                label="Enter your email"
                variant="outlined"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={
                  (formik.touched.email && formik.errors.email) || ' '
                }
              />
            )}
            {showRecaptcha && (
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={`${process.env.REACT_APP_CAPTCHA_SITE_KEY}`}
              />
            )}
            {!successStatus && (
              <Button variant="contained" className="button" type="submit">
                {translatePipe('send_recovery_link')}
                {pendingResetPassword && (
                  <CircularProgress
                    size={15}
                    thickness={5}
                    style={{ color: 'white' }}
                  />
                )}
              </Button>
            )}
            <Link className="link-to mt-20" to="/log-in">
              {translatePipe('return_to_log_in')}
            </Link>
          </form>
        </div>
      </div>
      <div className="footer flex j-s-e w-100" style={{ marginBottom: '2%' }}>
        <a className="link-to" href="https://www.softactivity.com/support/" target="_blank" rel="noopener noreferrer">
          {translatePipe('log_in_help')}
        </a>
        <a className="link-to" href="https://www.softactivity.com/support/" target="_blank" rel="noopener noreferrer">
          {translatePipe('contact_us')}
        </a>
      </div>
      <div
        className="footer flex j-s-e w-100"
        style={{ paddingBottom: '30px' }}
      >
        <a className="link-to" href="https://www.softactivity.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
          {translatePipe('privacy_policy')}
        </a>
        <a className="link-to" href="https://www.softactivity.com/terms-of-service/" target="_blank" rel="noopener noreferrer">
          {translatePipe('terms_of_service')}
        </a>
      </div>
    </div>
  );
}

export default ResetPassword;
