import { useNavigate } from 'react-router-dom';
import SaLogo from '../assets/sa_logo.png';
import '../App.scss';

const HeaderLogo = () => {
  const navigate = useNavigate();

  return (
    <div className="logo-wrapper">
      <img src={SaLogo} alt="" onClick={() => navigate('/')} />
    </div>
  );
};

export default HeaderLogo;
