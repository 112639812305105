import axios from 'axios';
import axiosInstance from './axiosInstance';
import { config } from '../config/index';

const setCsrf = () => axios.get(`${config.AUTH_URL}/set-csrf/`);

const signUp = (data: any) => axiosInstance.post(`${config.AUTH_URL}/sign-up/`, data);

const logIn = (data: any) => axiosInstance.post(`${config.AUTH_URL}/sign-in/`, data);

const logInOptions = () => axios.options(`${config.AUTH_URL}/sign-in/`);

const signOut = () => axios.get(`${config.AUTH_URL}/sign-out/`);

const resetPasswordCreate = (data: {
  email: string;
  recaptcha_code?: string;
}) => axiosInstance.post(`${config.AUTH_URL}/reset-password/`, data);

const resetPasswordCreateOptions = () => axios.options(`${config.AUTH_URL}/reset-password/`);

const recoveryPasswordCreate = (data: {
  password: string;
  recovery_code: string;
}) => axiosInstance.post(`${config.AUTH_URL}/recovery-password/`, data);

const getTenants = () =>
  axios
    .get(`${config.API_URL}/tenants/`)
    .then(resp => resp)
    .catch();

const verifyInviteCode = (data: { invite_code: string }) =>
  axiosInstance.post(`${config.API_URL}/invite/verify/`, data);

const acceptInviteCode = (data: { password?: string; invite_code: string }) =>
  axiosInstance.post(`${config.API_URL}/invite/accept/`, data);

const verifyRecoveryCode = (data: { recovery_code: string }) =>
  axiosInstance.post(`${config.AUTH_URL}/verify-recovery-code/`, data);

const authService = {
  setCsrf,
  signUp,
  logIn,
  logInOptions,
  signOut,
  resetPasswordCreate,
  resetPasswordCreateOptions,
  recoveryPasswordCreate,
  getTenants,
  verifyInviteCode,
  acceptInviteCode,
  verifyRecoveryCode
};

export default authService;
