import { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useFormik, FormikProvider } from 'formik';
import 'yup-phone';
import * as yup from 'yup';
import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import authService from '../api/auth_service';
import { tP, translatePipe } from '../i18n';
import { requiredPasswordField } from '../utils/validationField';
import HeaderLogo from '../components/HeaderLogo';
import '../App.scss';

const validationSchema = yup.object().shape({
  new_password: requiredPasswordField,
  confirm_password: yup
    .string()
    .oneOf([yup.ref('new_password')], tP('must_match_the_new_password'))
    .required(tP('confirm_password_is_required'))
});

function RecoveryPassword() {
  const navigate = useNavigate();
  const [pending, setPending] = useState<boolean>(false);
  const [isShowNewPassword, setIsShowNewPassword] = useState<boolean>(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] =
    useState<boolean>(false);
  const [pendingRecoveryPassword, setPendingRecoveryPassword] =
    useState<boolean>(false);
  const [errorDataDetail, setErrorDataDetail] = useState<string | null>(null);
  const [recoveryCodeVerified, setRecoveryCodeVerified] =
    useState<boolean>(false);

  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const recovery_code = searchParams.get('recovery_code');

  useEffect(() => {
    if (recovery_code) {
      setPending(true);
      authService
        .verifyRecoveryCode({ recovery_code })
        .then(() => {
          setPending(false);
          setRecoveryCodeVerified(true);
        })
        .catch((err: any) => {
          setPending(false);
          setErrorDataDetail(err?.response?.data?.detail);
        });
    }
  }, [recovery_code]);

  const formik = useFormik({
    initialValues: {
      new_password: '',
      confirm_password: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (recovery_code && !pendingRecoveryPassword) {
        let payload = { recovery_code, password: values.new_password };
        setPendingRecoveryPassword(true);
        setErrorDataDetail(null);
        authService
          .recoveryPasswordCreate(payload)
          .then(() => {
            setPendingRecoveryPassword(false);
            navigate('/');
          })
          .catch((err) => {
            setPendingRecoveryPassword(false);
            setErrorDataDetail(err?.response?.data?.detail);
          });
      }
    }
  });

  const renderErrorStatus = useMemo(() => {
    if (!recoveryCodeVerified) {
      return (
        <div className="flex a-i-c error-message">
          <ErrorOutlineOutlinedIcon />
          <div>
            <div>
              {translatePipe('error_recovery_code_message')}
              <Link className="link-to" to="/reset-password">
                {translatePipe('here')}
              </Link>
              .
            </div>
            {errorDataDetail && (
              <div className="mt-10">
                {translatePipe('details')}&nbsp;{errorDataDetail}
              </div>
            )}
          </div>
        </div>
      );
    }
    return null;
  }, [errorDataDetail, recoveryCodeVerified]);

  return (
    <FormikProvider value={formik}>
      <div className="page-wrapper flex f-d-col a-i-c">
        <HeaderLogo />
        <div className="body-wrapper flex a-i-c">
          {pending && (
            <CircularProgress
              size={45}
              thickness={5}
              style={{ color: '#88cbf0' }}
            />
          )}
          {!pending && !recoveryCodeVerified && renderErrorStatus}
          {!pending && recoveryCodeVerified && (
            <div className="form-wrapper flex f-d-col j-c a-i-c">
              <div className="title-form">
                {translatePipe('password_recovery_page_header')}
              </div>
              {renderErrorStatus}
              <form
                className="flex f-d-col w-100"
                autoComplete="off"
                onSubmit={formik.handleSubmit}
              >
                <FormControl
                  variant="outlined"
                  size="small"
                  error={
                    formik.touched.new_password &&
                    Boolean(formik.errors.new_password)
                  }
                >
                  <InputLabel htmlFor="standard-adornment-password">
                    {translatePipe('new_password_text_field_label')}
                  </InputLabel>
                  <OutlinedInput
                    id="new_password"
                    name="new_password"
                    label={translatePipe('new_password_text_field_label')}
                    type={isShowNewPassword ? 'text' : 'password'}
                    value={formik.values.new_password}
                    onChange={formik.handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setIsShowNewPassword(!isShowNewPassword)
                          }
                        >
                          {isShowNewPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText id="component-helper-text">
                    {(formik.touched.new_password &&
                      formik.errors.new_password) ||
                      ' '}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  variant="outlined"
                  size="small"
                  error={
                    formik.touched.confirm_password &&
                    Boolean(formik.errors.confirm_password)
                  }
                >
                  <InputLabel htmlFor="standard-adornment-password">
                    {translatePipe('confirm_password_text_field_label')}
                  </InputLabel>
                  <OutlinedInput
                    id="confirm_password"
                    name="confirm_password"
                    label={translatePipe('confirm_password_text_field_label')}
                    type={isShowConfirmPassword ? 'text' : 'password'}
                    value={formik.values.confirm_password}
                    onChange={formik.handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setIsShowConfirmPassword(!isShowConfirmPassword)
                          }
                        >
                          {isShowConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText id="component-helper-text">
                    {(formik.touched.confirm_password &&
                      formik.errors.confirm_password) ||
                      ' '}
                  </FormHelperText>
                </FormControl>
                <Button
                  variant="contained"
                  className="button w-100"
                  type="submit"
                >
                  {translatePipe('change_password')}
                  {pendingRecoveryPassword && (
                    <CircularProgress
                      size={15}
                      thickness={5}
                      style={{ color: 'white' }}
                    />
                  )}
                </Button>
              </form>
            </div>
          )}
        </div>
        <div
          className="footer flex j-s-e w-100"
          style={{ paddingBottom: '30px' }}
        >
          <a
            className="link-to"
            href="https://www.softactivity.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {translatePipe('privacy_policy')}
          </a>
          <a
            className="link-to"
            href="https://www.softactivity.com/terms-of-service/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {translatePipe('terms_of_service')}
          </a>
        </div>
      </div>
    </FormikProvider>
  );
}

export default RecoveryPassword;
