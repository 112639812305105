import { Link } from 'react-router-dom';
import { translatePipe } from '../i18n';
import HeaderLogo from '../components/HeaderLogo';
import '../App.scss';

const PageNotFound = () => {
  return (
    <div className="page-wrapper flex f-d-col a-i-c">
      <HeaderLogo />
      <div className="body-wrapper flex a-i-c">
        <div className="flex flex-col a-i-c">
          <h1>{translatePipe('page_not_found')}</h1>
          <div className="link-not-found-page">
            {translatePipe('go_to')}{' '}
            <Link className="link-to mt-20" to="/log-in">
              {translatePipe('log_in')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
