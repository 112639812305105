import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useFormik, FormikProvider } from 'formik';
import * as yup from 'yup';
import 'yup-phone';
import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
  Checkbox
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import authService from '../api/auth_service';
import { tP, translatePipe } from '../i18n';
import { requiredPasswordField } from '../utils/validationField';
import HeaderLogo from '../components/HeaderLogo';
import '../App.scss';

const validationSchema = yup.object().shape({
  new_password: requiredPasswordField,
  confirm_password: yup
    .string()
    .oneOf([yup.ref('new_password')], tP('must_match_the_new_password'))
    .required(tP('confirm_password_is_required'))
});

function InvitationPage() {
  const [isShowNewPassword, setIsShowNewPassword] = useState<boolean>(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] =
    useState<boolean>(false);
  const [pending, setPending] = useState<boolean>(false);
  const [pendingAccept, setPendingAccept] = useState<boolean>(false);
  const [isAgreedRules, setIsAgreedRules] = useState<boolean>(false);
  const [inviteData, setInviteData] = useState<{
    tenant: string;
    set_password: boolean;
  } | null>(null);
  const [errorDataDetail, setErrorDataDetail] = useState<string | null>(null);
  const [showTermError, setShowTermError] = useState<boolean>(false);
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const invite_code = searchParams.get('invite_code');

  useEffect(() => {
    if (isAgreedRules) {
      setShowTermError(false);
    }
  }, [isAgreedRules]);

  useEffect(() => {
    if (invite_code) {
      setPending(true);
      authService
        .verifyInviteCode({ invite_code })
        .then((resp) => {
          setPending(false);
          setInviteData(resp.data);
        })
        .catch((err: any) => {
          setPending(false);
          setErrorDataDetail(err?.response?.data?.detail);
        });
    }
  }, [invite_code]);

  const formik = useFormik({
    initialValues: {
      new_password: '',
      confirm_password: ''
    },
    validationSchema: inviteData?.set_password ? validationSchema : null,
    onSubmit: (values) => {
      if (isAgreedRules && invite_code && !pendingAccept) {
        let payload;
        if (inviteData?.set_password) {
          payload = { invite_code, password: values.new_password };
        } else {
          payload = { invite_code };
        }
        setPendingAccept(true);
        authService
          .acceptInviteCode(payload)
          .then((resp) => {
            setPendingAccept(false);
            if (resp?.data?.tenant_url)
              window.location.href = resp?.data?.tenant_url;
          })
          .catch(() => setPendingAccept(false));
      } else {
        setShowTermError(true);
      }
    }
  });

  const renderErrorStatus = useMemo(() => {
    if (errorDataDetail) {
      return (
        <div className="flex a-i-c error-wrapper">
          <ErrorOutlineOutlinedIcon />
          <div>
            {errorDataDetail}
            <div>{translatePipe('ask_to_resend_invitation')}</div>
          </div>
        </div>
      );
    }
    return null;
  }, [errorDataDetail]);

  return (
    <FormikProvider value={formik}>
      <div className="page-wrapper flex f-d-col a-i-c">
        <HeaderLogo />
        <div className="body-wrapper flex a-i-c">
          {pending && (
            <CircularProgress
              size={45}
              thickness={5}
              style={{ color: '#88cbf0' }}
            />
          )}
          {!pending && errorDataDetail && renderErrorStatus}
          {!pending && inviteData && (
            <div className="form-wrapper flex f-d-col j-c a-i-c">
              <div className="title-form">
                {translatePipe('invitation_page_header')}
              </div>
              <div className="text-form">
                {translatePipe('invitation_page_text_block1')}{' '}
                <b>{inviteData?.tenant}</b>{' '}
                {translatePipe('invitation_page_text_block2')}
              </div>
              <form
                className="flex f-d-col w-100 mt-20"
                autoComplete="off"
                onSubmit={formik.handleSubmit}
              >
                {inviteData?.set_password && (
                  <>
                    <FormControl
                      variant="outlined"
                      size="small"
                      error={
                        formik.touched.new_password &&
                        Boolean(formik.errors.new_password)
                      }
                    >
                      <InputLabel htmlFor="standard-adornment-password">
                        {translatePipe('new_password_text_field_label')}
                      </InputLabel>
                      <OutlinedInput
                        id="new_password"
                        name="new_password"
                        label={translatePipe('new_password_text_field_label')}
                        type={isShowNewPassword ? 'text' : 'password'}
                        value={formik.values.new_password}
                        onChange={formik.handleChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                setIsShowNewPassword(!isShowNewPassword)
                              }
                            >
                              {isShowNewPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      <FormHelperText id="component-helper-text">
                        {(formik.touched.new_password &&
                          formik.errors.new_password) ||
                          ' '}
                      </FormHelperText>
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      size="small"
                      error={
                        formik.touched.confirm_password &&
                        Boolean(formik.errors.confirm_password)
                      }
                    >
                      <InputLabel htmlFor="standard-adornment-password">
                        {translatePipe('confirm_password_text_field_label')}
                      </InputLabel>
                      <OutlinedInput
                        id="confirm_password"
                        name="confirm_password"
                        label={translatePipe(
                          'confirm_password_text_field_label'
                        )}
                        type={isShowConfirmPassword ? 'text' : 'password'}
                        value={formik.values.confirm_password}
                        onChange={formik.handleChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                setIsShowConfirmPassword(!isShowConfirmPassword)
                              }
                            >
                              {isShowConfirmPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      <FormHelperText id="component-helper-text">
                        {(formik.touched.confirm_password &&
                          formik.errors.confirm_password) ||
                          ' '}
                      </FormHelperText>
                    </FormControl>
                  </>
                )}
                <div className="flex a-i-c">
                  <Checkbox
                    checked={isAgreedRules}
                    onChange={() => setIsAgreedRules(!isAgreedRules)}
                    className="checkbox-form"
                  />
                  <div className="ml-10">
                    I agree to SoftActivity{' '}
                    <a
                      className="link-to"
                      href="https://www.softactivity.com/privacy-policy/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {translatePipe('privacy_policy')}
                    </a>{' '}
                    and{' '}
                    <a
                      className="link-to"
                      href="https://www.softactivity.com/terms-of-service/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {translatePipe('terms_of_service')}
                    </a>
                  </div>
                </div>
                {showTermError && (
                  <div className="ml-10 mt-10 error-term">
                    {translatePipe('accept_the_terms')}
                  </div>
                )}
                <div className="mt-20">
                  <Button
                    variant="contained"
                    className="button w-100"
                    type="submit"
                  >
                    {translatePipe('accept_invite')}
                    {pendingAccept && (
                      <CircularProgress
                        size={15}
                        thickness={5}
                        style={{ color: 'white' }}
                      />
                    )}
                  </Button>
                </div>
              </form>
              <div className="text-form mt-20">
                {translatePipe('invitation_page_text_block3')}
              </div>
            </div>
          )}
        </div>
        <div
          className="footer flex j-s-e w-100"
          style={{ paddingBottom: '30px' }}
        >
          <a
            className="link-to"
            href="https://www.softactivity.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {translatePipe('privacy_policy')}
          </a>
          <a
            className="link-to"
            href="https://www.softactivity.com/terms-of-service/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {translatePipe('terms_of_service')}
          </a>
        </div>
      </div>
    </FormikProvider>
  );
}

export default InvitationPage;
