import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from 'react-router-dom';
import SignUp from './pages/SignUp';
import LogIn from './pages/LogIn';
import Tenants from './pages/Tenants';
import InvitationPage from './pages/InvitationPage';
import ResetPassword from './pages/ResetPassword';
import RecoveryPassword from './pages/RecoveryPassword';
import PageNotFound from './pages/404';

const AppRoutes = () => {
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search).toString();

  return (
    <Router basename="/identity">
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Navigate
                to={'log-in' + (searchParams ? '?' + searchParams : '')}
              />
            </>
          }
        />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/log-in" element={<LogIn />} />
        <Route path="/tenants" element={<Tenants />} />
        <Route path="/invitation" element={<InvitationPage />} />
        <Route path="/recovery" element={<RecoveryPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
