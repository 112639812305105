import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { translatePipe } from '../i18n';
import authService from '../api/auth_service';
import HeaderLogo from '../components/HeaderLogo';
import '../App.scss';

type TenantType = {
  id: number;
  name: string;
  owner: number;
  url: string;
};

function Tenants() {
  const navigate = useNavigate();
  const [errorStatus, setErrorStatus] = useState<number | null>(null);
  const [pending, setPending] = useState<boolean>(false);
  const [tenants, setTenants] = useState<TenantType[] | undefined>(undefined);

  useEffect(() => {
    setPending(false);
    if (tenants && tenants.length === 1) {
      window.location.href = tenants[0].url;
    }
    if (tenants && tenants.length === 0) {
      navigate('/');
    }
  }, [tenants, navigate]);

  useEffect(() => {
    setPending(true);
    authService
      .getTenants()
      .then((resp) => {
        setTenants(resp?.data);
      })
      .catch((err) => {
        setPending(false);
        if (err?.response?.status === 401 || err?.response?.status === 403)
          navigate('/');
        if (err?.response?.status === 500)
          setErrorStatus(err?.response?.status);
      });
  }, [navigate]);

  const signOut = () => {
    authService.signOut().then(() => navigate('/'));
  };

  const renderErrorStatus = useMemo(() => {
    if ((errorStatus || tenants?.length === 0) && !pending) {
      return (
        <div className="flex a-i-c error-message">
          <ErrorOutlineOutlinedIcon />
          <div>{translatePipe('tenants_error_message')}</div>
        </div>
      );
    }
    return null;
  }, [errorStatus, pending, tenants]);

  const tenantListItem = (tenant: TenantType, index: number) => {
    return (
      <div
        key={index}
        className="tenant-item flex"
        onClick={() => {
          window.location.href = tenant.url;
        }}
      >
        {tenant.name}
      </div>
    );
  };

  return (
    <div className="page-wrapper flex f-d-col a-i-c">
      <HeaderLogo />
      <div className="body-wrapper flex a-i-c">
        <div className="form-wrapper flex f-d-col j-c a-i-c">
          <div className="flex f-d-col j-c a-i-c w-100">
            <div className="title-form">
              {translatePipe('select_an_organization')}
            </div>
            {renderErrorStatus}
            <div className="tenants-list">
              {!pending &&
                tenants &&
                tenants[0] &&
                tenants.map((tenant, index) => tenantListItem(tenant, index))}
              {pending && <CircularProgress />}
            </div>
          </div>
          <Button
            onClick={() => {
              signOut();
            }}
            variant="contained"
            className="button"
          >
            {translatePipe('log_out')}
          </Button>
        </div>
      </div>
      <div
        className="footer flex j-s-e w-100"
        style={{ paddingBottom: '30px' }}
      >
        <a
          className="link-to"
          href="https://www.softactivity.com/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {translatePipe('privacy_policy')}
        </a>
        <a
          className="link-to"
          href="https://www.softactivity.com/terms-of-service/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {translatePipe('terms_of_service')}
        </a>
      </div>
    </div>
  );
}

export default Tenants;
