import axios from 'axios';
import Cookies from 'js-cookie';
import authService from './auth_service';

let axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (config) => {
    const csrftoken = Cookies.get('csrftoken');
    config.headers['X-CSRFToken'] = csrftoken;
    return config;
  },
  (error) => {
    if (error.response.status === 403) {
      authService.setCsrf();
    }
  }
);

export default axiosInstance;
