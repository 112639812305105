import { tP } from '../i18n';
import * as yup from 'yup';

export const requiredEmailField = yup
  .string()
  .email(tP('email_invalid_error'))
  .required(tP('email_required_error'));

export const requiredPasswordField = yup
  .string()
  .matches(
    /^(?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9])\S{12,}$/,
    tP('password_complexity_message')
  )
  .required(tP('password_required_error'));
