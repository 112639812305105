import { Fragment, useEffect } from 'react';
import { IntlProvider } from 'react-intl';

import { messages } from './i18n';
import Routes from './Routes';
import authService from './api/auth_service';

import './App.scss';

function App() {
  useEffect(() => {
    authService.setCsrf();
  }, []);

  return (
    <IntlProvider
      textComponent={Fragment}
      locale="en"
      messages={messages['en']}
    >
      <Routes />
    </IntlProvider>
  );
}

export default App;
