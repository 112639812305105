import { FormattedMessage } from 'react-intl';

export const translatePipe = (id: string | undefined, value = {}) => {
  if (id) {
    return <FormattedMessage id={id} values={{ ...value }} />;
  }
  return '';
};

export const tP = (value: string) => () => translatePipe(value);
