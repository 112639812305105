import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormik, FormikProvider } from 'formik';
import * as yup from 'yup';
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import countries from '../utils/countries';
import authService from '../api/auth_service';
import 'yup-phone';
import { tP, translatePipe } from '../i18n';
import '../App.scss';
import {
  requiredEmailField,
  requiredPasswordField
} from '../utils/validationField';
import HeaderLogo from '../components/HeaderLogo';

interface Country {
  code: string;
  label: string;
  phone: string;
}

const validationSchema = yup.object({
  first_name: yup.string().required(tP('first_name_required_error')),
  last_name: yup.string().required(tP('last_name_required_error')),
  company_name: yup.string().required(tP('company_name_required_error')),
  country: yup.mixed().required(tP('country_required_error')),
  email: requiredEmailField,
  password: requiredPasswordField,
  phone_number: yup.string().required(tP('phone_number_required_error'))
});

function SignUp() {
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const [pending, setPending] = useState<boolean>(false);
  const [countrySearch, setCountrySearch] = useState<string>('');

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      company_name: '',
      country: '',
      email: '',
      password: '',
      phone_number: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      let trimValues: any = {};
      for (let prop in values) {
        trimValues[prop] = values[prop].trim
          ? values[prop].trim()
          : values[prop];
      }
      setPending(true);
      authService
        .signUp(trimValues)
        .then(() => {
          alert('Signed up');
          setPending(false);
        })
        .catch((err: any) => {
          setPending(false);
          const responseErrors = err?.response?.data;
          let errors: any = {};
          for (let errorKey in responseErrors) {
            errors[errorKey] = Array.isArray(responseErrors[errorKey])
              ? responseErrors[errorKey].join(', ')
              : responseErrors[errorKey];
          }
          formik.setErrors(errors);
        });
    }
  });

  const countryToFlag = (isoCode: string) => {
    return typeof String.fromCodePoint !== 'undefined'
      ? (isoCode || '')
          .toUpperCase()
          .replace(/./g, (char) =>
            String.fromCodePoint(char.charCodeAt(0) + 127397)
          )
      : isoCode || '';
  };

  return (
    <FormikProvider value={formik}>
      <div className="page-wrapper flex f-d-col a-i-c">
        <HeaderLogo />
        <div className="body-wrapper flex a-i-c">
          <div className="form-wrapper flex f-d-col j-c a-i-c">
            <div className="title-form">
              {translatePipe('sign_up_page_header')}
            </div>
            <form
              className="flex f-d-col w-100"
              autoComplete="off"
              onSubmit={formik.handleSubmit}
            >
              <TextField
                size="small"
                name="first_name"
                id="first_name"
                label="First Name"
                variant="outlined"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                error={
                  formik.touched.first_name && Boolean(formik.errors.first_name)
                }
                helperText={
                  <>
                    {(formik.touched.first_name && formik.errors.first_name) ||
                      ' '}
                  </>
                }
              />
              <TextField
                size="small"
                name="last_name"
                id="last_name"
                label="Last Name"
                variant="outlined"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                error={
                  formik.touched.last_name && Boolean(formik.errors.last_name)
                }
                helperText={
                  <>
                    {(formik.touched.last_name && formik.errors.last_name) ||
                      ''}
                  </>
                }
              />
              <TextField
                size="small"
                name="company_name"
                id="company_name"
                label="Company or organization"
                variant="outlined"
                value={formik.values.company_name}
                onChange={formik.handleChange}
                error={
                  formik.touched.company_name &&
                  Boolean(formik.errors.company_name)
                }
                helperText={
                  <>
                    {(formik.touched.company_name &&
                      formik.errors.company_name) ||
                      ''}
                  </>
                }
              />
              <Autocomplete
                filterOptions={(options: Country[]) => {
                  return options.filter(({ label, code }: Country) => {
                    if (
                      code.toLowerCase().startsWith(countrySearch.toLowerCase())
                    ) {
                      return true;
                    }

                    return label
                      .toLowerCase()
                      .startsWith(countrySearch.toLowerCase());
                  });
                }}
                onClose={() => setCountrySearch('')}
                options={countries}
                autoHighlight
                getOptionLabel={(option: Country) => option.label}
                onChange={(e, value) => {
                  formik.setFieldValue('country', value);
                }}
                renderOption={(option) => (
                  <>
                    <span>{countryToFlag(option.code)}</span>&nbsp;&nbsp;
                    {option.label} ({option.code})
                  </>
                )}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      size="small"
                      name="country"
                      id="country"
                      label="Your country"
                      variant="outlined"
                      value={formik.values.country}
                      onChange={(e) => setCountrySearch(e.target.value)}
                      error={
                        formik.touched.country && Boolean(formik.errors.country)
                      }
                      helperText={
                        <>
                          {(formik.touched.country && formik.errors.country) ||
                            ' '}
                        </>
                      }
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: formik.values.country &&
                          (formik.values.country as any) instanceof Object && (
                            <InputAdornment position="start">
                              <span style={{ marginLeft: '9px' }}>
                                {countryToFlag(
                                  (formik.values.country as any)?.code
                                )}
                              </span>
                            </InputAdornment>
                          )
                      }}
                    />
                  );
                }}
              />
              <TextField
                size="small"
                name="email"
                id="email"
                label="Enter your email"
                variant="outlined"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={
                  <>{(formik.touched.email && formik.errors.email) || ' '}</>
                }
              />
              <FormControl
                variant="outlined"
                size="small"
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
              >
                <InputLabel htmlFor="standard-adornment-password">
                  {translatePipe('password_text_field_label')}
                </InputLabel>
                <OutlinedInput
                  id="password"
                  name="password"
                  label="Password"
                  type={isShowPassword ? 'text' : 'password'}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setIsShowPassword(!isShowPassword)}
                      >
                        {isShowPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText id="component-helper-text">
                  {
                    <>
                      {(formik.touched.password && formik.errors.password) ||
                        ' '}
                    </>
                  }
                </FormHelperText>
              </FormControl>
              <TextField
                size="small"
                type="number"
                name="phone_number"
                id="phone_number"
                label="Phone number"
                variant="outlined"
                value={formik.values.phone_number}
                onChange={formik.handleChange}
                error={
                  formik.touched.phone_number &&
                  Boolean(formik.errors.phone_number)
                }
                helperText={
                  <>
                    {(formik.touched.phone_number &&
                      formik.errors.phone_number) ||
                      ' '}
                  </>
                }
                onKeyDown={(event) => {
                  const ctrlKeyPressed = event.ctrlKey || event.metaKey;

                  if (
                    !ctrlKeyPressed &&
                    event.key.length === 1 &&
                    /^[a-zA-z]+/.test(event.key)
                  ) {
                    event.preventDefault();
                  }
                }}
              />
              <Button
                variant="contained"
                className="button w-100"
                type="submit"
                disabled={pending}
              >
                {translatePipe('sign_up')}
                {pending && (
                  <CircularProgress
                    size={15}
                    thickness={5}
                    style={{ color: 'white' }}
                  />
                )}
              </Button>
              <Link className="link-to mt-20" to="/log-in">
                {translatePipe('return_to_log_in')}
              </Link>
            </form>
          </div>
        </div>
        <div className="footer flex j-s-e w-100" style={{ marginBottom: '2%' }}>
          <a
            className="link-to"
            href="https://www.softactivity.com/support/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {translatePipe('log_in_help')}
          </a>
          <a
            className="link-to"
            href="https://www.softactivity.com/support/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {translatePipe('contact_us')}
          </a>
        </div>
        <div
          className="footer flex j-s-e w-100"
          style={{ paddingBottom: '30px' }}
        >
          <a
            className="link-to"
            href="https://www.softactivity.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {translatePipe('privacy_policy')}
          </a>
          <a
            className="link-to"
            href="https://www.softactivity.com/terms-of-service/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {translatePipe('terms_of_service')}
          </a>
        </div>
      </div>
    </FormikProvider>
  );
}

export default SignUp;
