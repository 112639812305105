import { useState, useMemo, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { TextField, Button } from '@material-ui/core';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import authService from '../api/auth_service';
import { tP, translatePipe } from '../i18n';
import { requiredEmailField } from '../utils/validationField';
import HeaderLogo from '../components/HeaderLogo';
import '../App.scss';

const validationSchema = yup.object({
  email: requiredEmailField,
  password: yup.string().required(tP('password_required_error'))
});

function LogIn() {
  const navigate = useNavigate();
  const [errorStatus, setErrorStatus] = useState<number | null>(null);
  const [showRecaptcha, setShowRecaptcha] = useState<boolean>(false);
  const [errorDataDetail, setErrorDataDetail] = useState<string | null>(null);
  const [pending, setPending] = useState<boolean>(false);
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  useEffect(() => {
    authService
      .getTenants()
      .then((resp) => {
        if (resp.data.length === 1) {
          window.location.href = resp.data[0].url;
        } else if (resp.data.length > 1) {
          navigate('/tenants');
        }
      })
      .catch(() => null);
  }, [navigate]);

  useEffect(() => {
    authService.logInOptions().then((resp) => {
      setShowRecaptcha(resp?.data.recaptcha.required);
    });
  }, []);

  const onSuccessLogin = () => {
    setPending(false);
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);

    if (searchParams.get('next')) {
      window.location.href = `${url.origin}${searchParams.get('next')}`;
    } else {
      setPending(true);
      authService
        .getTenants()
        .then((resp) => {
          setPending(false);
          if (resp?.data.length === 1) {
            window.location.href = resp?.data[0].url;
          } else if (resp?.data.length > 1) {
            navigate('/tenants');
          } else {
            setErrorStatus(200);
          }
        })
        .catch(() => {
          setPending(false);
          setErrorStatus(405);
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values: any) => {
      let recaptchaCode: any = '';
      if (showRecaptcha) {
        recaptchaCode = await recaptchaRef?.current?.executeAsync();
        recaptchaRef?.current?.reset();
      }
      let trimValues: any = {};
      for (let prop in values) {
        trimValues[prop] = values[prop].trim
          ? values[prop].trim()
          : values[prop];
      }
      setPending(true);
      setErrorStatus(null);
      setErrorDataDetail(null);
      if ((recaptchaCode && showRecaptcha) || !showRecaptcha) {
        const payload = recaptchaCode
          ? { ...trimValues, recaptcha_code: recaptchaCode }
          : trimValues;
        authService
          .logIn(payload)
          .then(() => onSuccessLogin())
          .catch((err) => {
            setPending(false);
            if (
              err?.response?.status === 401 ||
              err?.response?.status === 403 ||
              err?.response?.status === 500
            ) {
              setErrorStatus(err?.response?.status);
              setErrorDataDetail(err?.response?.data?.detail);
            }
          });
      }
    }
  });

  const renderErrorStatus = useMemo(() => {
    if (errorStatus) {
      return (
        <div className="flex a-i-c error-message">
          <ErrorOutlineOutlinedIcon />
          {errorStatus === 200 && (
            <div>{translatePipe('login_error_no_organizations')}</div>
          )}
          {errorStatus === 401 && (
            <div>
              {translatePipe('login_error_401_message')}
              <Link className="link-to" to="/reset-password">
                {translatePipe('recover_here')}
              </Link>
            </div>
          )}
          {errorStatus === 403 && (
            <div>
              <div>{translatePipe('login_error_403_message')}</div>
              <div>
                {translatePipe('details')}&nbsp;{errorDataDetail}
              </div>
            </div>
          )}
          {errorStatus === 405 && (
            <div>{translatePipe('tenants_error_message')}</div>
          )}
          {errorStatus &&
            errorStatus !== 200 &&
            errorStatus !== 401 &&
            errorStatus !== 403 &&
            errorStatus !== 405 && (
              <div>{translatePipe('login_error_message')}</div>
            )}
        </div>
      );
    }
    return null;
  }, [errorStatus, errorDataDetail]);

  return (
    <div className="page-wrapper flex f-d-col a-i-c">
      <HeaderLogo />
      <div className="body-wrapper flex a-i-c">
        <div className="form-wrapper flex f-d-col j-c a-i-c">
          <div className="title-form">{translatePipe('login_page_header')}</div>
          {renderErrorStatus}
          <form
            className="flex f-d-col w-100"
            autoComplete="off"
            onSubmit={formik.handleSubmit}
          >
            <TextField
              size="small"
              name="email"
              id="outlined-basic"
              label={translatePipe('email_text_field_label')}
              variant="outlined"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={
                <>{(formik.touched.email && formik.errors.email) || ' '}</>
              }
            />
            <TextField
              size="small"
              id="filled-password-input"
              name="password"
              label={translatePipe('password_text_field_label')}
              type="password"
              autoComplete="current-password"
              variant="outlined"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={
                <>
                  {(formik.touched.password && formik.errors.password) || ' '}
                </>
              }
            />
            {showRecaptcha && (
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={`${process.env.REACT_APP_CAPTCHA_SITE_KEY}`}
              />
            )}
            <Button
              variant="contained"
              className="button"
              type="submit"
              disabled={pending}
            >
              {translatePipe('log_in')}
              {pending && (
                <CircularProgress
                  size={15}
                  thickness={5}
                  style={{ color: 'white' }}
                />
              )}
            </Button>
            <Link className="link-to mt-20" to="/reset-password">
              {translatePipe('troubles_logging_in')}
            </Link>
            <div className="flex mt-20">
              {translatePipe('dont_have_account')}&nbsp;&nbsp;
              {/* <Link className="link-to" to="/sign-up">
                {translatePipe('sign_up_now')}
              </Link> */}
              <a
                className="link-to"
                href="https://www.softactivity.com/work/trial/"
              >
                {translatePipe('sign_up_now')}
              </a>
            </div>
            <div className="flex mt-10">{translatePipe('or_ask_admin')}</div>
          </form>
        </div>
      </div>
      <div
        className="footer flex j-s-e w-100"
        style={{ paddingBottom: '30px' }}
      >
        <a
          className="link-to"
          href="https://www.softactivity.com/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {translatePipe('privacy_policy')}
        </a>
        <a
          className="link-to"
          href="https://www.softactivity.com/terms-of-service/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {translatePipe('terms_of_service')}
        </a>
      </div>
    </div>
  );
}

export default LogIn;
